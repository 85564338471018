.field-status {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 28px;
  border-radius: 13px;
  &-name {
    display: block;
    color: $color-dark;
    font-weight: normal;
    text-align: center;
  }
  &.active {
    background-color: #cef0da;
  }
  &.finished {
    background-color: #f2c0bd;
  }
}
