@import 'variables';
@import 'theme_colors';

@import '@octano/global-ui/dist/scss/styles.scss';

// Layouts
@import './layout_private';

// Views
@import './home';
@import './login';
@import './contracts';
@import './date_personal';
@import './permits_vacations';

// Components
@import './modal';
// @import './drawer';
@import './tab';
@import './card_layout';
@import './field_status';
@import './generate_info';
@import './tooltip_app';
@import './collapsable';
@import './input_date_outlined';
@import './error_table_data';
@import './dropdown_user';

// Fuentes
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-display: sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
}

td {
  @media (max-width: $sm) {
    padding: 5px !important;
  }
}
.icon-gray-light {
  color: $body-color;
}

.text-light {
  color: #838383 !important;
}

.g-sidenav-root .logo{
  transition: all 0.5s ease-out;
}