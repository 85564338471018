.collapsable {
  &-head {
    border-width: 1px;
    border-style: solid;
    border-color: $secondary;
    // border: 1px solid #5990cb;
    border-radius: 4px;
    cursor: pointer;
    &-layout {
      max-height: 27px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 6px 8px;
    }
    &-title {
      color: $secondary;
      font-size: 18px;
      font-weight: 300;
      margin-left: 5px;
    }
    @media (min-width: $sm) {
      display: none;
    }
  }
  &-content {
    opacity: 0;
    height: 0px;
    margin-top: 10px;
    overflow: hidden;
    transition: 300ms ease;
    @media (min-width: $sm) {
      opacity: 1;
      height: 100%;
      overflow: initial;
    }
    &-active {
      opacity: 1;
      height: 100%;
      overflow: initial;
      transition: 300ms ease;
    }
  }
}
