.drop-down-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.drop-down {
  display: none;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 0px 4px 4px;
  opacity: 0;
  z-index: 20;

  &-body {
    background-color: white;
    position: absolute;
    top: 40px;
    right: -43px;
    position: absolute;
    z-index: 20;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-right: 7px solid transparent;
      border-top: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 14px solid white;
      top: -21px;
      right: 20px;
    }
  }

  &-open {
    position: absolute;
    display: initial;
    opacity: 1;
    top: 36px;
    right: 50px;
  }

  &-user {
    display: flex;
    margin: 23px 25px 10px;
    gap: 11px;
    border-bottom: 1px solid #0056a3;
    padding-bottom: 22px;

    &-photo {
      margin: 0;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 14px;
      color: $text-medium;
      word-break: break-all;
      width: 125px;
      line-height: 1;
    }

    &-name {
      font-weight: bold;
    }
  }

  &-action {
    display: grid;
    gap: 3px;
    margin-bottom: 10px;
  }
}

//Botones de accion del drop down
.drop-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 31px;
  height: 35px;
  color: $primary;
  transition: 0.3s ease;

  &:hover {
    background: #ddefff 0% 0% no-repeat padding-box;
    transition: 0.3s ease;
    .drop-btn-text {
      color: $text-medium;
    }
  }

  &-text {
    transition: 0.3s ease;
    font: normal normal normal 14px/18px Source Sans Pro;
    letter-spacing: 0px;
    color: $primary;
  }
}
