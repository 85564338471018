//Estilos que se repetien entre algunos de los modales de app
.modal {
  &-header {
    display: grid;
    margin-bottom: 30px;
    gap: 20px;
    border-bottom: 0;
    justify-content: center;
    justify-items: center;
  }
  &-subtitle {
    display: block;
    font-size: 16px;
    color: $body-color;
    font-weight: normal;
    text-align: center;
  }
  &-action {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: $sm) {
      gap: 15px;
      flex-direction: column-reverse;
    }
    &-btn {
      width: 100%;
      max-height: 65px;
      margin-top: 0.5rem;
      &-small {
        max-width: 210px;
      }
      @media (max-width: $sm) {
        max-width: initial;
      }
    }
  }
  &-message {
    font: normal normal normal 18px/30px Source Sans Pro;
    text-align: center;
    color: #838383;
  }
}
