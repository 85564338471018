.generate-info-form-title {
  color: $primary;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.generate-info-form {
  display: grid;

  gap: 30px;
  &-col-3 {
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: $sm) {
      grid-template-columns: 1fr;
    }
  }
  &-col-2 {
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: $sm) {
      grid-template-columns: 1fr;
    }
  }
  &-col-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
