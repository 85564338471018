.main_private_content {
  display: grid;
  width: 100%;
  height: 100%;
  padding: 22px 12px;
}

.private_layout_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal normal normal 30px/38px Source Sans Pro;
  padding: 22px 12px 0 12px;
  &.active {
    @media (max-width: $sm) {
      justify-content: flex-end;
    }
  }
  .private_menu {
    display: none;
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    @media (max-width: $sm) {
      display: block;
    }
  }
  &_btn {
    display: none;
    border: 0;
    background-color: transparent;

    @media (max-width: $sm) {
      display: inline-flex;
      align-items: center;
    }
  }
  &_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: $sm) {
      display: initial;
    }
  }
  &_title {
    font-weight: 400;
    color: $color-medium;
    @media (max-width: $sm) {
      display: none;
    }
  }
  &_title_mobile {
    display: none;
    font-weight: 400;
    color: $color-medium;
    @media (max-width: $sm) {
      padding: 10px 12px;
      display: block;
    }
  }
  .btn-open {
    @media (max-width: $sm) {
      display: none;
    }
  }
  @media (max-width: $sm) {
    display: grid;
    grid-auto-flow: column;
    background-color: white;
    padding: 10px 5px;
  }
}

.user_perfil {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 13px;
  margin: 0;
  cursor: pointer;
  padding: 14px 11px;
  &:hover {
    background-color: white;
  }
  &_photo {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 999px;
    object-position: top center;
  }
  &_name {
    display: block;
    width: 100%;
    font: normal normal normal 16px/20px Source Sans Pro;
  }
}

//LogoGlobal300
.global-300-logo {
  margin-left: auto;
  justify-self: end;
  max-width: 144px;
  margin-top: 2rem;
}
