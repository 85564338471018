// --------- DATE INPUT ---------
// Contenedor de todos los elementos del date input outlined

.g-date-picker-container {
  &.g-date-picker-outlined-container {
    cursor: pointer;
    position: relative;
    min-height: 40px !important;
    height: 40px !important;
    width: 100%;
    min-width: 130px;
    margin-bottom: 1rem;

    .g-date-input-outlined {
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: $secondary;
      // border: 1px solid #5990cb;
      position: absolute;
      height: 100%;
      max-height: 40px;
      width: 100%;
    }
    &.g-date-picker-container.without-value
      .react-date-picker--closed.react-date-picker--enabled
      .react-date-picker__wrapper {
      background-color: #ffffff !important;
    }
    .g-date-picker-container.with-value
      .react-date-picker--closed
      .react-date-picker__wrapper,
    .g-date-picker-container
      .react-date-picker--disabled
      .react-date-picker__wrapper {
      box-shadow: none !important;
    }

    // Contenedor del datepicker
    .g-date-input {
      position: absolute;
      width: 100%;
      @media (max-width: $mobile) {
        background-color: #fff;
      }
    }
    .g-input-label {
      padding-left: 13px;
    }

    // Grupo de 3 inputs para escribir la fecha en formato --/--/----
    .react-date-picker__inputGroup {
      padding: 0px;
      :nth-child(2) {
        padding-left: 16px;
        margin-top: 0px;
      }
      .react-date-picker__inputGroup__input {
        padding-bottom: 0px;
        height: calc(40px - 20px);
        color: #1e1e1e;
        font-size: 1.15rem;
        margin-top: 0px;
      }
      // Formato del texto de cada input
      span {
        color: #1e1e1e;
        font-size: 1.15rem;
        margin-top: 0px;
      }
    }

    //Cuerpo del calendario
    .react-calendar {
      box-shadow: 0px 3px 10px #00000029;
      border-radius: 4px;
      overflow: hidden;
      border: 0px;

      //Cabecera del calendario, donde se ven las flechas, mes y año actual
      .react-calendar__navigation__arrow {
        font-size: 1.5rem;
        &.react-calendar__navigation__prev2-button,
        &.react-calendar__navigation__next2-button {
          display: none;
        }
      }
      //Nombre de días de la semana LUN, MAR, MIE
      .react-calendar__month-view__weekdays__weekday {
        margin-bottom: 17px;
        abbr {
          text-decoration: none;
          color: $body-color;
          font: normal normal normal 12px/15px Source Sans Pro;
          letter-spacing: 0px;
        }
      }
      //Cuerpo donde se ve la cuadricula de la fecha
      &__viewContainer {
        padding: 0px 9px 21px;
      }
      //Todos los numeros del día
      .react-calendar__tile.react-calendar__month-view__days__day {
        font: normal normal normal 12px/15px Source Sans Pro;
      }

      .react-calendar__tile:hover,
      .react-calendar__tile--active,
      .react-calendar__tile--now {
        border-radius: 22px;
      }
    }

    //Calendario cuando esta active y desplegado
    .react-date-picker--open {
      & ~ .g-input-label {
        opacity: 0;
      }
    }

    // Botón para borrar
    .react-date-picker__clear-button {
      display: none;
      padding: 15px 10px 0px 0px;
      margin-bottom: 1rem;
    }

    // Caja de fondo del input
    .react-date-picker__wrapper {
      align-items: center;
      border: 0px;
      border-radius: 4px;
      background-color: white;
    }

    //Cuando el input no está focus y no tiene value
    &.without-value {
      .react-date-picker--closed.react-date-picker--enabled {
        input::placeholder,
        .react-date-picker__inputGroup__divider {
          color: transparent;
        }

        ~ .g-input-label {
          transform: initial;
        }

        .react-date-picker__inputGroup__input,
        .react-date-picker__inputGroup__leadingZero {
          color: transparent;
        }
      }
    }

    // Se muestra el borde cuando tiene valor y no está activo
    &.with-value {
      .g-input-label {
        display: none;
      }
      .react-date-picker__calendar-button {
        display: none;
      }

      .react-date-picker__clear-button {
        display: initial;
      }
      .react-date-picker--closed {
        ~ .g-input-border {
          display: block;
        }
      }
    }
  }
}
