.tooltip-app {
  cursor: pointer;
  z-index: 10;
  &-base {
    .tooltip {
      margin-top: 7px !important;
      opacity: 1;
      &-inner {
        background-color: $secondary;
        border-radius: 5px;
        padding: 10px;
        font: normal normal normal 14px/20px Source Sans Pro;
        box-shadow: 0px 6px 8px #79797948;
        &:hover {
          color: white;
          text-decoration: none;
        }
      }
      .arrow::before {
        border-top-color: $secondary !important;
      }
    }
  }
}
