.error_tabla {
  margin: auto;
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 290px;
  text-align: center;
  &_title {
    font-size: 32px;
    font-weight: 500;
  }
}
