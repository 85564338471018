.btn-total-salary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 205px;
  height: 33px;
  background-color: $primary;
  border-radius: 0px 0px 5px 4px;
  color: white;
  font-weight: bold;
  margin-right: -3.5rem;
  @media (max-width: $sm) {
    margin-right: 0.5rem;
  }
}
