.table-filter {
  display: grid;
  grid-auto-flow: column;
  align-items: end;
  gap: 9px;
  grid-auto-columns: minmax(auto, 230px);
  margin-bottom: 30px;
  @media (max-width: $sm) {
    grid-auto-flow: initial;
    grid-auto-columns: initial;
  }
  .g-date-picker-container {
    border-radius: 4px;
  }
  &-btn {
    border: 0px;
    height: 100%;
    max-width: 90px;
    min-height: 40px;
    max-height: 40px;
    margin-bottom: 1rem;
    @media (max-width: $sm) {
      height: initial !important;
    }
  }
  &-actions {
    display: contents;
    @media (max-width: $sm) {
      display: flex;
      justify-content: space-between;
      gap: 9px;
    }
  }
}

//Cambio de tamaño en los estilos por defecto de global-ui
.g-outlined-select > div:nth-child(2) {
  @media (min-width: $sm) {
    height: 40px;
  }
}

.filter-height-btn {
  width: 100%;
  max-width: 200px;
  height: 100%;
  min-height: 40px !important;
  max-height: 40px;
  margin-bottom: calc(1rem + 15px);
}

.download-btn {
  font-weight: bold;
  @media (max-width: $sm) {
    display: none;
  }
}
