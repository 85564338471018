//Tabs
.tab_container {
  display: flex;
  overflow: hidden;
  width: min-content;
  border-radius: 5px 5px 0 0;
  @media (max-width: $sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    position: relative;
    width: 100%;
  }
}

.tab {
  display: block;
  text-align: center;
  width: 180px;
  color: $color-light;
  font: normal normal normal 14px/18px Source Sans Pro;
  background-color: #f7f7f7;
  transition: 1500ms all ease;
  &_label {
    display: block;
    padding: 12px;
  }
  &_line {
    display: block;
    border-radius: 5px;
    background-color: #f7f7f7;
    width: 90%;
    margin: 0 auto;
    height: 3px;
    transition: 300ms ease all;
  }
  &:hover {
    font-weight: bold;
    background-color: white;
    color: $secondary;
    text-decoration: none;
    transition: 300ms ease all;
    .tab_line {
      display: block;
      border-radius: 5px;
      background-color: $secondary;
      width: 90%;
      margin: 0 auto;
      height: 3px;
      transition: 300ms ease all;
    }
  }
  @media (max-width: $sm) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1000px;
    width: 100%;
    transition: 800ms all ease;
  }
}

.tab_active {
  font-weight: bold;
  background-color: white;
  color: $secondary;
  .tab_line {
    display: block;
    border-radius: 5px;
    background-color: $secondary;
    width: 90%;
    margin: 0 auto;
    height: 3px;
  }
}

// TabSlider
.tabCurrentView,
.tabCurrentView + .tab {
  position: relative;
  right: 0px;
  left: 0px;
  transition: 800ms all ease;
}
.tabBeforeCurrentView {
  left: -1000px;
  transition: 800ms all ease;
}

.tab-slide-control-left,
.tab-slide-control-right {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  color: #cccccc;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  @media (min-width: $sm) {
    display: none;
  }
}

.tab-slide-control-left {
  left: 0;
}

.tab-slide-control-right {
  right: 0;
}
