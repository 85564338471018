//Los nombres de estas variables son los mismo que la librería global UI
// Son para reemplazar los colores prederterminados

// @Pantallas - Resoluciones
$xl: 1200px;
$lg: 960px;
$md: 768px;
$sm: 576px;
$mobile: 280px;

// @Colores - Customizados
:root {
  --gray: #c5c5c5;
  --gray-ligth: #cccccc;
  --gray-dark: #838383;
  --white: #f7f7f7;
  --blue-ligth: #4990d0;
  --black: #1e1e1e;
  --blue: #0056a3;
}

$primary: #0056a3;
$secondary: #4990d0;
$gray-dark: #838383;
$text-medium: #5a5a5a;
$white-gray: #cccccc;

.text-gray {
  color: #787878;
}
