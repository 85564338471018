.logo_uni {
  max-width: 258px;
  margin: 0 auto;
  object-fit: contain;
}

.login_actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
}

.recover_success_btn {
  width: 100%;
  max-width: 285px;
}

.recover_confirm_card {
  max-width: 497px;
  margin: auto;
}

.revocer_confrim_tooltip {
  position: absolute;
  right: 19px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.recover_logo {
  max-width: 270px;
  margin: 0 auto;
  object-fit: contain;
}

.logo-3000 {
  width: 100%;
  max-width: 182px;
}
