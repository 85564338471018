.date-personal {
  &-info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 25px;
    @media (max-width: $sm) {
      grid-template-columns: initial;
    }
  }
  &-aditional-info {
    display: block;
    gap: 44px;
    width: 100%;
    @media (min-width: $sm) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

//parts
.picker-photo {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  max-width: 160px;
  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 160px;
    height: 160px;
    background-color: #f4f4f4;
    max-width: 160px;
    max-height: 160px;
    &-preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &-icon {
    color: $white-gray;
  }
  &-btn {
    width: 100%;
    height: 100%;
  }
  &-uploaded {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    justify-content: center;
    max-width: 190px;
    @media (max-width: $sm) {
      gap: 12px;
    }
  }
}

.picker-photo-modal {
  &-subtitle {
    display: block;
    font-size: 16px;
    color: $body-color;
    margin-bottom: 51px;
    font-weight: normal;
  }
  &-info {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 3rem;
    gap: 22px;
  }
}

//Cambios de estilos del componente react-image-crop
.ReactCrop__crop-selection {
  //Para quitar el border de guiones que tiene parecido a border-style dashed
  box-shadow: 0 0 0 9999em #83838399;
  border-radius: 6px;
  border-image-source: none;
  border: 0px;
}

.ReactCrop__drag-handle::after {
  //Oculto los cuadrados de las esquina de la seleccion de recorte
  opacity: 0;
}

.carga-date {
  &-wrapper {
    &-icon {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      right: 8px;
      bottom: 0;
      top: -1rem;
    }
  }
}
